import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import { useInView } from "react-intersection-observer"

const Box = styled.div`
    width: 100%; 
    height: 100%; 
    position: relative;
    transition-property: transform, opacity; 
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    opacity: 0;
    transform: translate3d(0, 20%, 0);
    transition-duration: 1.5s;
    transition-delay: 0.2s;

    ${props =>
      props.time &&
      css`
        transition-duration: ${props => props.time}s;
      `}

    ${props =>
      props.delay &&
      css`
        transition-delay: ${props => props.delay}s;
      `}

    ${props =>
      props.big &&
      css`
        transform: translate3d(0, 100%, 0);
      `}

    .is-loaded & {
        &.is-visible{
            transform: none; 
            opacity: 1;
        }
    }
`

export default props => {
  const [box, boxInView, entry] = useInView()

  useEffect(() => {
    if (boxInView) {
      entry.target.classList.add("is-visible")
    }
  })

  return (
    <Box
      ref={box}
      time={props.time}
      delay={props.delay}
      small={props.small}
      big={props.big}
    >
      {props.children}
    </Box>
  )
}
